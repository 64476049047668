import React, { Component } from "react"
import Header from "./components/header"
import Footer from "./components/footer"
import {
  RiAmazonFill,
  RiPaypalFill,
  RiNetflixFill,
  RiGoogleFill,
  RiMicrosoftFill,
  RiAppleFill,
  RiStarFill,
} from "react-icons/ri"
import {
  BsCollection,
  BsFiles,
  BsFunnel,
  BsChatQuote,
  BsDroplet,
  BsLayoutWtf,
  BsCheck,
} from "react-icons/bs"
import image1 from "../../static/images/landing-img1.png"
import image2 from "../../static/images/main1.jpg"
import review1 from "../../static/images/review1.jpg"
import review2 from "../../static/images/review2.jpg"
import review3 from "../../static/images/review3.jpg"

class Landing extends Component {
  state = {}

  render() {
    return (
      <div className="landing-container-cover-v1">
        <Header />
        <div className="landing-body-v1">
          <div className="landing-cont1-v1">
            <div className="land-cont1-left-v1">
              <div>
                <div className="land-text1-v1">Online Resume Builder</div>
                <div className="land-text2-v1">
                  The Resume Builder with total flexibility
                </div>
                <div className="land-text3-v1">
                  *It take 5 minutes to build a professional resume
                </div>
              </div>
              <a
                style={{ textDecoration: "none" }}
                href="https://app.plancv.com/login"
                alt=" "
              >
                <div className="land-btn1-v1">Build My Resume Now</div>
              </a>
            </div>
            <div className="land-cont1-right-v1">
              <img
                className="land-image1-v1"
                src={image1}
                alt=" "
                height="auto"
                width="100%"
              />
            </div>
          </div>
          <div className="landing-cont2-v1">
            <div className="land-text4-v1">
              Features that make your Resume Unique
            </div>
            <div className="land-feature-cont-v1">
              <div className="land-feature-item-cont-v1 land-color1">
                {" "}
                <div className="land-text5-v1 ">Templates</div>
              </div>
              <div className="land-feature-item-cont-v1 land-color2">
                {" "}
                <div className="land-text5-v1">colors</div>
              </div>
              <div className="land-feature-item-cont-v1 land-color3">
                {" "}
                <div className="land-text5-v1">backgrounds</div>
              </div>
              <div className="land-feature-item-cont-v1 land-color1">
                {" "}
                <div className="land-text5-v1 ">fonts</div>
              </div>
              <div className="land-feature-item-cont-v1 land-color2">
                {" "}
                <div className="land-text5-v1">rearrange</div>
              </div>
              <div className="land-feature-item-cont-v1 land-color3">
                {" "}
                <div className="land-text5-v1">18 sections</div>
              </div>
            </div>
            <div>
              <img
                className="land-image2-v1"
                src={image2}
                alt=" "
                height="auto"
                width="100%"
              />
            </div>
          </div>
          <div className="landing-cont3-v1">
            <div className="land-text6-v1">
              Plancv get hired people at top companies
            </div>

            <div className="land-brand-cont-v1">
              <div className="land-brand-item-v1">
                <RiGoogleFill color="#62686d" size="3.5em" />
              </div>
              <div className="land-brand-item-v1">
                <RiAppleFill color="#62686d" size="3.5em" />
              </div>
              <div className="land-brand-item-v1">
                <RiMicrosoftFill color="#62686d" size="3.5em" />
              </div>
              <div className="land-brand-item-v1">
                <RiAmazonFill color="#62686d" size="3.5em" />
              </div>
              <div className="land-brand-item-v1">
                <RiPaypalFill color="#62686d" size="3.5em" />
              </div>
              <div className="land-brand-item-v1">
                <RiNetflixFill color="#62686d" size="3.5em" />
              </div>
            </div>
          </div>
          {/* from pricing page */}
          <div
            className="land-text6-v1"
            style={{ marginTop: "100px", marginBottom: "30px" }}
          >
            Things that make our Resume Builder bold
          </div>
          <div className="pricing-cont3">
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsCollection color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Eyes catching templates
              </div>
              <div className="pricing-features-box-desc-v1">
                Will structured, creative and professional templates that can
                easily draw the recruiter's attention to your Resume/CV.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsDroplet color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Customizable design
              </div>
              <div className="pricing-features-box-desc-v1">
                Trending and premium fonts, color combinations and backgrounds
                bring the unique reasonable design for your Resume/CV.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsLayoutWtf color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Limitless flexibility
              </div>
              <div className="pricing-features-box-desc-v1">
                More than 18 pre-build sections and you can add new sections by
                changing any of these sections and rearrange the position of
                sections.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsFunnel color="#62686d" size="1.7em" />
              </div>

              <div className="pricing-features-box-title-v1">ATS friendly</div>
              <div className="pricing-features-box-desc-v1">
                Most enterprise companies use Applicant Tracking System (ATS)
                systems for checking and filtering resumes based on keywords.
                Our Resume Builder is optimized for this system.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsChatQuote color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Live feedback and pre-build guide
              </div>
              <div className="pricing-features-box-desc-v1">
                Live feedback, recommendation, placeholder guides, and grammer
                check for each word that you type.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsFiles color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Multiple Resume/CV and pages
              </div>
              <div className="pricing-features-box-desc-v1">
                You can create and edit 10 Resume/CV at the same time with
                unlimited pages for each Resume/CV.
              </div>
            </div>
          </div>

          <div className="landing-cont4-v1">
            <div className="land-text6-v1">Customers Love Us</div>
            <div className="land-review-cont-v1">
              <div className="land-review-item-cont-v1 ">
                <div className="land-review-item-left-v1">
                  <div className="land-review-img-v1">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={review1}
                      alt=" "
                      width="50px"
                      height="50px"
                    />
                  </div>
                  <div className="land-review-star-v1">
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                  </div>
                </div>
                <div className="land-review-item-right-v1">
                  I love the plancv design, also it save my time...
                </div>
              </div>
              <div className="land-review-item-cont-v1 ">
                <div className="land-review-item-left-v1">
                  <div className="land-review-img-v1">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={review2}
                      alt=" "
                      width="50px"
                      height="50px"
                    />
                  </div>
                  <div className="land-review-star-v1">
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                  </div>
                </div>
                <div className="land-review-item-right-v1">
                  It is extremely useful, and have great customization too, the
                  content is very clear and easy to read.
                </div>
              </div>
              <div className="land-review-item-cont-v1 ">
                <div className="land-review-item-left-v1">
                  <div className="land-review-img-v1">
                    <img
                      style={{ borderRadius: "50%" }}
                      src={review3}
                      alt=" "
                      width="50px"
                      height="50px"
                    />
                  </div>
                  <div className="land-review-star-v1">
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                    <RiStarFill color="yellow" />
                  </div>
                </div>
                <div className="land-review-item-right-v1">
                  Great for someone, who want to make and customize CV for each
                  job, and we can able to create multiple resumes.
                </div>
              </div>
            </div>
          </div>
          {/* from pricing page */}

          <div className="pricing-last-v1">
            <div className="pricing-last-text-v1">
              Building a Basic Resume/CV is Free!
              <br />
              <span className="pricing-last-span-v1">
                *No credit card required!
              </span>
            </div>
            <div>
              <a
                className="pricing-last-btn-v1"
                href="https://app.plancv.com/signup"
                alt="plancv"
              >
                Build My Resume Now
              </a>
            </div>
          </div>

          {/*           
          <div className="landing-cont5-v1">
            <div className="land-cont5-left-v1">
              Now you are just a click away from your professional Resume.
            </div>
            <a
              style={{ textDecoration: "none" }}
              href="https://app.plancv.com/signup"
              alt=" "
            >
              <div className="land-cont5-right-v1">Create My Resume</div>
            </a>
          </div>
        */}
        </div>
        <Footer />
      </div>
    )
  }
}

export default Landing
